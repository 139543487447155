.icon {
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  color: white;
  fill: white;

  &-burger {
    background-image: url('../assets/icons/menu.svg');
  }

  &-close {
    background-image: url('../assets/icons/cerrar.svg');
  }

  &-whatsapp {
    background-image: url('../assets/icons/whatsapp.svg');
  }

  &-cancel {
    background-image: url('../assets/icons/cancelar.svg');
  }

  &-instagram {
    background-image: url('../assets/icons/ig.svg');
  }

  &-dateTime {
    background-image: url('../assets/icons/Fechahora.svg');
  }

  &-facebook {
    background-image: url('../assets/icons/fb.svg');
  }

  &-thumbsup {
    background-image: url('../assets/icons/thumbsup.svg');
  }

  &-write {
    background-image: url('../assets/icons/anotarse.svg');
  }

  &-edit {
    background-image: url('../assets/icons/editar.svg');
  }

  &-delete {
    background-image: url('../assets/icons/eliminar.svg');
  }

  &-clases {
    background-image: url('../assets/icons/clases.svg');
  }

  &-heart {
    background-image: url('../assets/icons/corazon.svg');
  }

  &-warning {
    background-image: url('../assets/icons/warning.svg');
  }

  &-profile {
    background-image: url('../assets/icons/profile.svg');
  }

  &-musculacion {
    background-image: url('../assets/icons/musculacion.svg');
  }
}
