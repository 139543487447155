.rec-arrow:hover:enabled, .rec-arrow:focus:enabled {
  background-color: black !important;
}

.rec-pagination {
  display: none !important;
}

.hideArrows {
  .rec-arrow {
    display: none;
  }
}

.customizedSlider {
  .sliderImage {
    height: 100%;
  }

  .slide {
    max-height: 300px;
    overflow: hidden;
  }

  .slideWrapper {
    height: 300px;
    width: auto;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;

    @media(max-width: 1024px) {
      margin: 0;
    }
  }
}
