@import 'colors';
@import 'inputs';
@import 'navigation';
@import 'icons';
@import 'loader';
@import 'homepage';
@import 'claseRow';
@import 'claseCard';
@import 'register';
@import 'modal';
@import 'profile';
@import 'admin';
@import 'musculacion';
@import 'instagram';
@import 'claseTableWrapper';
@import 'resetPassword';
@import 'carousel';
@import 'ceos';
@import 'construccion';

* {
  box-sizing: border-box;
}

body {
  position: relative;
  background-color: $dark-gray;
  color: #fafafa;
  font-family: 'Roboto Condensed', sans-serif;
  min-height: 100vh;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  
   &.ReactModal__Body--open {
    position:fixed;
    overflow:hidden;
    width: 100%;
  }
}

.base {
  min-height: 100vh;
}

button {
  border: none;
  background: transparent;
}

.pageWrapper {
  text-align: center;
  padding: 1rem 1rem 1rem;
  max-width: 1200px;
  margin: 0 auto;

  &.home {
    padding: 0;
    max-width: initial;
    width: 100%;
    margin: 0;
  }

  @media (max-width: 767px) {
    padding: 4rem 0.75rem;
  }
}

a {
  color: #fafafa;
}


.inscripcion {
  &-title {
    padding: 1rem 0 0;
    display: block;
  }
}

.navLinks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.loadingScreen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: white;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}
