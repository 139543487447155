.construccion {
  &.modal{
    min-height: 400px;
  }
  .Title  {
    margin: 0 0 1rem;
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding: 0 0.5rem;
  }
  .s-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    margin: 0;
    padding: 0;
  
    @media(min-width: 768px) {
      flex-direction: row;
      box-sizing: border-box;
    }
  
    .column {
      width: 100%;
  
      @media(min-width: 768px) {
        width: 45%;
      }
    }
  
    .footerTitle {
      font-size: 20px;
      margin-top: 3rem;
      margin-bottom: 1rem;
      width: 100%;
      display: block;
      font-weight: bold;
  
      @media(min-width: 768px) {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
  
    }
  
    .footerText {
      font-size: 14px;
      text-transform: initial;
      margin: 0;
    }
  
    .icon {
      width: 2rem;
      height: 2rem;
      margin-right: 10px;
      display: inline-block;
    }
  
    .divider {
      border: 1px solid #fafafa;
      width: 20%;
      margin: 1rem 0;
    }
  
    .contactForm {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;
  
      .inputWrapper {
        margin-left: 0;
        margin-right: 0;
        width: auto;
      }
  
      .btn {
        margin-top: -0.5rem;
      }
    }
  }
  
}
