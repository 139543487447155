@import 'colors';

.claseRow {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid $disabled-gray;
  padding-bottom: 10px;
  padding-top: 10px;

  &.disabled {
    color: $disabled-gray;
  }

  &:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .title {
    flex: 2;
    padding: 0 10px;
  }
}

.dayWrapper {
  margin-top: 3rem;

  &:first-of-type{
    margin-top:0.5rem;
  }
}

.categoryFilter {
  @media(min-width: 1024px) {
    margin: 0 auto 1rem;
    max-width: 300px;
  }
}
.dayTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.messageWrapper {
  text-align: center;

  .icon {
    width: 2rem;
    height: 2rem;
    margin: 2rem auto 0;
  }
  
  .title {
    font-size: 25px;
  }
}
