@import 'colors';

.homeSection {
  width: 100%;
  min-height: 30vh;
  overflow: hidden;

  &:not(.s-footer) {

    @media (max-width: 1024px) {
      max-height: 100%;
    }
  }

  &.s-clases {
    padding: 2rem 0;

    @media(min-width: 1024px) {
      padding: 2rem 2rem;
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}

.sectionTitle {
  margin: 0 0 1rem;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  padding: 0 0.5rem;
}

.header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  min-height: 13rem;
  padding: 6rem 0 2rem;
  background: black;

  @media(max-width: 1024px) {
    display: none;
  }

  &-linkWrapper {
    width: 30rem;
    margin-top: 1.5rem;
    justify-content: space-around;
    display: flex;
    font-size: 18px;
  }

  .logo {
    display: block;
    height: 100px;
    width: 193px;
    background-image: url('../assets/logo_white.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.s-wpp {
  background: url('../assets/whatsapp.jpg');
  background-size: cover;
  padding: 3rem 0 4rem;

  @media(max-width: 1024px) {
    padding: 2rem 1rem;
  }

  .icon-whatsapp {
    margin: 0 auto;
    display: block;
    height: 2rem;
    width: 2rem;
  }

  .sectionTitle {
    margin: 1rem 0 0;

    @media(max-width: 1024px) {
      font-size: 18px;
    }
  }
}

.sectionSubtitle {
  margin: 0 0 1rem;
  font-size: 30px;
  display: block;

  @media(max-width: 1024px) {
    font-size: 18px;
  }
}

.heroBanner {
  width: 100%;
}

.s-banner {
  max-height: 70vh;

  .placeholder {
    height: 900px;
    width: 100vw;

    @media(max-width: 1024px) {
      height: 200px;
    }
  }
}

.s-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  margin: 0;
  padding: 0rem 2rem 3rem;

  @media(min-width: 768px) {
    flex-direction: row;
    padding: 1rem 8rem;
    box-sizing: border-box;
  }

  .column {
    width: 100%;

    @media(min-width: 768px) {
      width: 22%;
    }
  }

  .footerTitle {
    font-size: 20px;
    margin-top: 3rem;
    margin-bottom: 1rem;
    width: 100%;
    display: block;
    font-weight: bold;

    @media(min-width: 768px) {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

  }

  .footerText {
    font-size: 14px;
    text-transform: initial;
    margin: 0;
  }

  .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 10px;
    display: inline-block;
  }

  .divider {
    border: 1px solid #fafafa;
    width: 20%;
    margin: 1rem 0;
  }

  .contactForm {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    .inputWrapper {
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }

    .btn {
      margin-top: -0.5rem;
    }
  }
}
