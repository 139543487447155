@import 'colors';

input,
select,
textarea {
  font-family: inherit;
  text-transform: initial;
  font-size: inherit;
}

button, input[type="submit"] {
  font-family: inherit;
  text-transform: inherit;
  font-size: inherit;
}

.textInput {
  border: 2px solid $disabled-gray;
  border-radius: 0;
  text-align: center;
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
  color: #fafafa;
  padding-bottom: 0.5rem;
  margin-bottom: 0.2rem;
}

.formColumns {
  display: flex;
  justify-content: space-between;

  @media(max-width: 1024px) {
   flex-direction: column;
  }

  .smallSelectWrapper {
    width: 50%;
    margin: 0 0 1rem;

    @media(max-width: 1024px) {
      width: 100%;
      max-width: initial;
    }

    & .inputWrapper {
      margin: 0 auto 1rem;
    }
  }
  
  &.three {
    .inputWrapper {
      width: 30%;
      @media(max-width: 1024px) {
        width: 100%;
      }
    }     
  }

  .inputWrapper {
    width: 45%;
    
    @media(max-width: 1024px) {
      width: 100%
    }
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 15rem;
  margin: 0 auto 2rem;

  & .arrow {
    position: absolute;
    right: 6px;
    top: 7px;
    color: $disabled-gray;
  }
}

.formWrapper {
  padding: 2rem 3rem;
}

.errorText {
  font-size: 14px;
  color: $red;

  @media(max-width: 1024px) {
    text-align: center;
    width: 100%;
  }
}

.helperText {
  font-size: 14px;
  color: $disabled-gray;
}

.btn {
  background: #fafafa;
  color: black;
  border-radius: 0;
  border: none;
  font-weight: bold;
  padding: 0.8rem 1.5rem;
  font-family: 'Roboto Condensed', sans-serif;
  cursor: pointer;
  text-decoration: none;
  display: block;
  margin: 0 auto;
  max-width: 15rem;

  &:disabled {
    background-color: $disabled-gray;
  }

  &.small {
    font-size: 14px;
    padding: 0.5rem;
  }

  :hover {
    opacity: 0.8;
  }
}

.select {
  background: transparent;
  border: 1px solid $disabled-gray;
  color: #fafafa;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  padding: 0.3rem 1rem 0.3rem 0.4rem;

  &:disabled {
    color: $disabled-gray;
  }
}

.selectOption {
  background: $black;
  font-family: inherit;
}

.inscripcion-title {
  font-size: 14px;
  margin-bottom: 1rem;
}

.smallSelectWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 240px;
  margin: 0 auto;

  .selectLabel {
    width: 100%;
    margin-bottom: 0.5rem;
    color: gray;
  }

  & .inputWrapper {
    width: 48%;
  }
}
