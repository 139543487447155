.ceoWrapper {
  display: flex;
flex-direction: column;
width: 300px; 
@media(max-width: 1024px) {
  width: 100%;
}
}

.ceosContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media(max-width: 1024px) {
    a {
      width: 50%;
    }
  }
}
