#profile {
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  
  p {
    margin: 0.3rem 0 0;
  }
  .icon {
    width: 2rem;
    height: 2rem;
    margin-top: 4rem; 
  }
  .profileLabel {
    font-weight: bold;
  }
  .claseContainer {
    margin-top: 2rem;
  }
  .disclaimer {
    margin-top: 2rem;
    font-style: italic;
  }
}
