.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black;
  color: #fafafa;
  padding: 1rem;
  overflow-y: auto;
  cursor: pointer;
  
  .icon-close{
    width: 2rem;
    height: 2rem;
    margin-left: auto;
    i { background-color: white};
  }

  @media(min-width: 1024px) {
    top: 5vh;
    left: 25%;
    width: 50%;
    bottom: initial;
    min-height: 650px;
    max-height: 90vh;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 51;
  background-color: rgba(0,0,0,.5);
}
