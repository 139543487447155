.instagramSlider {
  .slideWrapper {
    height: 100%;
    width: auto;
    display: flex;
    margin: 0 1rem;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;

    @media(max-width: 1024px) {
      margin: 0;
    }
  }
}

.s-instagram {
  background: black;
  padding: 5rem 1rem 6rem;

  @media(max-width: 1024px) {
    padding: 2rem 1rem;
  }

  .sectionTitle {
    margin: 0;
    padding: 0;
    width: auto;

    @media(max-width: 1024px) {
      font-size: 20px;
      text-align: center;
    }
  }

  .icon-instagram, .icon-musculacion {
    height: 2rem;
    width: 2rem;
    margin-right: 0.5rem;

    @media(max-width: 1024px) {
      margin-bottom: 0.5rem;
    }
  }
}

.instagram-title {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  text-decoration: none;

  @media(max-width: 1024px) {
    text-align: left;
    flex-direction: column;
  }
}
