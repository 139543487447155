.pageWrapper.admin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.usersWrapper {
  width: 50%;
  margin: 0;

  @media(max-width: 1024px) {
    width: 100%;
    padding-top: 1rem;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto 0.5rem;
  }

  ul {
    text-align: left;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .userList {
    &Name {
      @media(max-width: 1024px) {
        max-width: 75%;
      }
    }

    & .btn {
      margin: 0;
    }
  }

  li {
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: 1rem;

    &.notVerified {
      color: $red;
    }

    &.lowAbono {
      color: $light-blue;
    }
  }
}

.icon-thumbsup {
  width: 2rem;
  height: 2rem;
  margin: 2rem auto 0.5rem;

}

.adminTitle {
  margin-bottom: 2rem;
  width: 100%;
}

.subtitle {
  font-size: 20px;
  margin: 3rem auto 1rem;
}

.actionsWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  .btn {
    margin: 0 10px;
  }
}

.userDataWrapper {
  margin: 0 auto;
  width: 70%;

  .claseContainer {
    margin-top: 2rem;

    .claseItemRowWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
    }

    .icon-delete {
      width: 0.8rem;
      height: 0.8rem;
    }

    .claseItem {
      margin: 0;
    }
  }

  @media(max-width: 1024px) {
    width: 90%
  }
}

.addAbonoWrapper {
  margin-top: 3rem;
  text-align: center;
}

.abonoTitle {
  margin-bottom: 1rem;
  display: block;
}

.userDataLabel {
  font-weight: bold;
}

.userDataTitle {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.clasesWrapper {
  width: 45%;

  @media(max-width: 1024px) {
    width: 100%
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto 0.5rem;
    color: white;
  }
}

.sectionTitle {
  font-size: 25px;
}

.noClasses {
  color: $disabled-gray;
}

.usersFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  background: $disabled-gray;
  padding: 0.5rem;
  justify-content: flex-start;
  flex-wrap: wrap;

  .filterLabel { 
    width: 100%;
    text-align: left;
    margin-bottom: 0.5rem;
  }

  .btn.red {
    background: $red;
    margin: 0;

    &.selected {
      border: 2px solid white;
    }
  }

  .btn.lightBlue {
    background: $light-blue;
    margin: 0 0 0 0.5rem;

    &.selected {
      border: 2px solid white;
    }
  }
  .userSearchBar {
    border: 0;
    margin-top: 1rem;
    padding: 10px;
    width: 100%;
  }
}
