.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
	background-color: #111;
  z-index: 999999;
  
  &.modalLoader {
    height: 100%;
    width: 100%;
    
    @media screen and (min-width: 1024px) {
      left: 0;
      right: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      width: auto;
      height: auto;
    }
  }

	@media screen and (max-width: 400px) {
		flex-direction: column;
	}
}

.loading {
  width: 60px;
	height: auto;
  display: flex;
  justify-content: space-between;

	@media screen and (max-width: 400px) {
		margin: 0;
		margin-bottom: 100px;
	}
	&__circle {
		width: 10px;
		height: 10px;
		background-color: #fff;
		border-radius: 50%;
		animation: loading 1s infinite;
		-webkit-animation: loading 1s infinite;
		&:nth-child(2) {
			animation-delay: 0.1s;
			-webkit-animation-delay: 0.1s;
		}
		&:nth-child(3) {
			animation-delay: 0.2s;
			-webkit-animation-delay: 0.2s;
		}
	}
}

@keyframes loading {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes loading {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

.centerLoader {
  width: 100%;
  display: flex;
  justify-content: center;
}
