#password {
  .icon-heart {
    height: 2rem;
    width: 2rem;
    margin: 2rem auto 0;
  }

  .title {
    font-weight: bold;
    font-size: 28px;
  }

  .subtitle {
    font-size: 25px;
  }
}
