.clase-container {
  margin-top: 2rem;
}

.box {
  margin-bottom: 2rem;
  text-align: center;

  .title,
  .subtitle {
    margin: 0;
  }

  .icon-write {
    width: 2rem;
    height: 2rem;
    margin: 3rem auto 0;
  }

  .icon-delete,
  .icon-edit, 
  .icon-cancel {
    width: 1rem;
    height: 1rem;
    color: black;
  }

  @media(max-width: 1024px) {
    padding: 0;
  }
}

.buttonsWrapper {
  margin: 1rem 0 2rem;
  
  .btn {
    display: inline;
  }

  .btn:last-child {
    margin-left: 10px;
  }
}

.students-container {
  margin-bottom: 2rem;

  @media(min-width:1024px) {
    max-width: 500px;
    margin: 0 auto 2rem;
  }
}

.confirmed-students {
  font-size: 14px;
  background: $dark-gray;
  padding: 1rem 2rem 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .title {
    font-size: 16px;
    padding: 0;
    margin: 0 auto;
  }

  .btn {
    &.deleteAll {
      margin: 0 auto;
    }
  }

  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 1.6rem;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 20px;
      }

      .btn {
        margin: 0 0 0 auto;
      }
    }
  }
}

.claseInfo {
  text-align: center;
  font-size: 28px;
  padding: 1rem 2rem 2rem;
}

.disclaimer {
  font-size: 12px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-top: 0;
  }
}

.clasesMobileWrapper {
  overflow-y: auto;
  max-height: 40vh;
  padding: 1rem 2rem;

  @media(min-width:1024px) {
    max-height: 80vh;
  }
}
