.claseTableWrapper {
  display: flex;
  background-color: $black;

  .dayWrapper {
    margin: 0;
    width: calc(100% / 6);
    overflow: hidden;
  }

  .dayTitle {
    padding: 1.5rem;
    display: block;
    margin: 0;
    font-weight: initial;
  }

  .clasesDayWrapper {
    border: 1px solid $disabled-gray;
    height: 95%;
    border-bottom: none;
    padding: 1rem 0 0;
  }

  .time,
  .teacher {
    display: block;
    width: 100%;
  }

  .time {
    font-weight: bold;
    padding-bottom: 5px;
  }

  .title {
    margin: 0 1rem;
    color: white;
    font-weight: bold;
    display: block;
    padding: 0.5rem;
  }

  .teacher {
    padding: 5px 0;
  }

  .ClaseTableItem {
    margin-bottom: 1rem;
  }

  .dayBottomDivider {
    margin: 1rem auto 0;
    border-bottom: 1px solid $disabled-gray;
    width: 40px;
    display: block;
  }
}

.filterButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
}

.buttonCategory {
  padding: 0.5rem 1rem;
  color: white;
  margin-left: 1rem;

  &.selected {
    border: 1px solid white;
  }
}

.martial {
  background-color: #b30d0d
}

.fitness {
  background-color: #4a008c
}

.indoor {
  background-color: #07559b
}

.musculacion {
  background-color: #1e601f
}

.infantil {
  background-color: #E65100;
}

.noClases {
  padding: 2rem;
  display: flex;
  justify-content: center;

  span {
    font-size: 20px;
  }
}
