@import 'colors';

.navbar {
  width: 100%;
  height: 4rem;
  background: $black;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: 1rem 0;
  z-index: 50;
  position: fixed;

  &-links-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .btn {
      margin-left: 1rem;
    }

    @media(max-width: 1024px) {
      display: none;
    }
  }

  &-brand {
    .logo {
      display: block;
      height: 40px;
      width: 100px;
      background-image: url('../assets/logo_white.png');
      background-size: contain;
      background-repeat: no-repeat;
    }

    .logo-item {
      display: none;

      @media(max-width: 1024px) {
        display: block;
      }
    }

    .redes-item {
      display: flex;
      width: 4rem;
      justify-content: space-between;

      .icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      @media(max-width: 1024px) {
        display: none;
      }
    }

    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
    width: 100%;

    @media(max-width: 1024px) {
      width: 100%
    }
  }

  &-burger-menu {
    height: 40px;
    width: 40px;
    padding: 0;
    background-size: contain;
    background-repeat: no-repeat;
    display: none;

    @media(max-width: 1024px) {
      display: block;
    }
  }
}

.drawer {
  display: none;

  @media(max-width: 1024px) {
    display: block;
    z-index: 49;
    position: fixed;
  }

  &-links {
    width: 100%;
    flex-direction: column;
    padding: 0 2rem 2rem;
    box-sizing: border-box;
    z-index: 30;
    position: absolute;
    top: -20rem;
    display: flex;
    transition: top .8s;
    -webkit-transition: top .8s;
    -moz-transition: top .8s;
    padding: 0;
  }

  @media(max-width: 1024px) {
    &-linkWrapper button, &-linkWrapper a  {
      padding: 1rem 0;
      width: 100%;
      display: block;
    }
  }

  &-wrapper {
    padding: 5rem 6rem 2rem;
    text-align: center;
    background-color: $black;
    width: 100vw;
  }

  &.active {
    .drawer-links {
      top: 0rem;
      height: 100vh;
    }
  }

  &-overlay {
    background-color: $black;
    height: 100%;
    opacity: 0;
    transition: opacity .8s;
    -webkit-transition: opacity .8s;
    -moz-transition: opacity .8s;
    width: 100vw;

    &.active {
      opacity: 0.5;
      position: relative;

    }
  }

  &-link {
    color: #fafafa;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
  }

  &-divider {
    border: 1px solid $disabled-gray;
    display: none;
    margin: 0;

    @media(max-width: 1024px) {
      display: block;
    }
  }
}
