  .musc {
    &-wrapper {
      padding: 2rem 3rem;
      background: black;
      width: 100%;
      margin-bottom: 2rem;

      .icon {
        width: 3rem;
        height: 3rem;
        margin: 0 auto;
      }

      @media (max-width: 1024px) {
        padding: 2rem 1rem;
      }
    }

    &-form {
      .selectLabel {
        color: white;
      }
    }

    &-description {
      font-size: 20px;
      max-width: 90%;
      margin: 0 auto 2rem;

      @media (max-width: 1024px) {
        font-size: 14px;
        max-width: 100%;
      }
    }
  }

  .noUserDisclaimer {
    color: $disabled-gray;
    margin: 0 0 1rem;
  }
